import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      width: '100%',
    },

    backgroundDefault: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },

    backgroundSecondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },

    backgroundFog: {
      backgroundColor: theme.palette.fog.main,
      color: theme.palette.fog.contrastText,
    },

    backgroundHome: {
      background:
        'linear-gradient(90deg, rgba(137,204,202,1) 0%, rgba(0,0,180,1) 100%)',
      color: theme.palette.common.white,
    },

    backgroundMembership: {
      background:
        'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,180,1) 100%)',
      color: theme.palette.common.white,
    },

    backgroundContracts: {
      background:
        'linear-gradient(90deg, rgba(137,204,202,1) 0%, rgba(255,255,255,1) 100%)',
      color: theme.palette.common.white,
    },

    container: {
      position: 'relative',
      display: 'flex',
    },

    fullHeight: {
      minHeight: `calc(100vh - ${theme.settings.topBarHeight}px)`,
      display: 'flex',
    },

    image: {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    imageFilter: {
      filter: 'opacity(0.8) saturate(0.5) contrast(1.2)',
    },
  }),

  { name: 'ContentStripe' },
)

export default useStyles
