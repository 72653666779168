import type { Theme as MuiTheme } from '@material-ui/core'
import {
  colors,
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core'
import type {
  Palette as MuiPalette,
  PaletteColor,
} from '@material-ui/core/styles/createPalette'
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows'
import _ from 'lodash'

import { THEMES } from 'src/constants'

import * as shadows from './shadows'
import typography from './typography'

export interface Palette extends MuiPalette {
  accent: PaletteColor
  tertiary: PaletteColor
  fog: PaletteColor
}

export interface Theme extends MuiTheme {
  name: string
  palette: Palette
  settings: Record<string, any>
}

interface ThemeConfig {
  responsiveFontSizes?: boolean
  theme?: string
}

interface ThemeOptions {
  name?: string
  overrides?: Record<string, any>
  palette?: Record<string, any>
  settings?: Record<string, any>
  shadows?: MuiShadows
  typography?: Record<string, any>
}

const baseOptions: ThemeOptions = {
  settings: {
    topBarHeight: 100,
  },

  overrides: {
    MuiButton: {
      sizeLarge: {
        padding: '6px 18px',
      },
    },

    MuiCard: {
      root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    },

    MuiCardHeader: {
      action: {
        marginTop: 'auto',
      },
    },

    MuiCardActions: {
      root: {
        marginTop: 'auto',
      },
    },

    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },

    MuiDialogActions: {
      root: {
        padding: 16,
      },

      spacing: {
        '& > :not(:first-child)': {
          marginLeft: 16,
        },
      },
    },

    MuiDialogContent: {
      root: {
        paddingRight: 16,
        paddingLeft: 16,
        display: 'flex',
        flexDirection: 'column',
      },
    },

    MuiDialogTitle: {
      root: {
        paddingRight: 16,
        paddingLeft: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },

    MuiDivider: {
      light: {
        backgroundColor: colors.common.white + '3',
      },
    },

    MuiFormControl: {
      fullWidth: {
        // Fixes labels being full width as well which led to invisible clickable areas.
        '& .MuiFormControlLabel-root': {
          paddingRight: 16,
          marginRight: 'auto',
        },
      },
    },

    MuiFormHelperText: {
      root: {
        '&:first-letter': {
          textTransform: 'uppercase',
        },

        // Display multiple errors as list items. The TextField error wrapper can't be changed to 'ul'.
        '&.Mui-error > span': {
          marginLeft: 16,
          display: 'list-item',
        },
      },
    },

    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },

    MuiInputLabel: {
      // Fixes an issue where select labels can overflow the select input.
      formControl: {
        maxWidth: 'calc(100% - 35px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },

    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },

    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },

    MuiTableCell: {
      root: {
        '& .MuiTypography-body1': {
          fontSize: 'inherit', // Fixes different default font-sizes when using the Typography component inside a table cell.
        },
      },

      head: {
        get fontWeight() {
          return typography.fontWeightBold
        },
      },
    },
  },

  typography,
}

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,

    palette: {
      type: 'light',

      // palette colors explained:
      // https://mui.com/material-ui/customization/palette/#color-tokens

      action: {
        active: '#123e59',
      },

      background: {
        default: colors.common.white,
        paper: colors.common.white,
      },

      primary: {
        main: '#0000b4',
        light: '#a7c3da',
        dark: '#00007a',
        contrastText: '#fff',
      },

      secondary: {
        main: '#333333',
        light: '#555555',
        dark: '#000000',
        contrastText: '#ffffff',
      },

      tertiary: {
        main: '#d9d9d9',
        light: '#ececec',
        dark: '#ccc',
        contrastText: '#000',
      },

      accent: {
        main: '#89CCCA',
        light: '#B2ECEB',
        dark: '#0F2E45',
        contrastText: '#000000',
      },

      fog: {
        main: '#d9d9d9',
        light: '#ececec', // not provided by the style guide
        dark: '#ccc',
        contrastText: '#333333',
      },

      text: {
        primary: '#333333',
        secondary: '#757575',
      },
    },

    shadows: shadows.softShadows,
  },
]

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme)

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    ;[themeOptions] = themesOptions
  }

  let theme = createMuiTheme(_.merge({}, baseOptions, themeOptions))

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme as Theme
}
