import type { FC } from 'react'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import clsx from 'clsx'

import { TestIds } from 'src/testIds'
import CertificateStripe from 'src/components/CertificateStripe/CertificateStripe'
import ContactForm from 'src/components/ContactForm/ContactForm'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Page from 'src/components/Page/Page'

import useRegions from '../../hooks/useRegions'

import useStyles from './ContactView.styles'

const ContactTestIds = TestIds.views.contact

const ContactView: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state: regionsState } = useRegions()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  })

  // Prepare contact sections data
  const contactSections = [
    {
      contacts: [
        {
          name: t('Views.Contact.hzvContactText'),
          phonePrimary: t('Views.Contact.hzvContactPhone'),
          phoneSecondary: '',
        },
        {
          name: t('Views.Contact.hzvContactTextBW'),
          phonePrimary: t('Views.Contact.hzvContactPhoneBw'),
          phoneSecondary: '',
        },
      ],
      title: t('Views.Contact.hzvContactTitle'),
    },
    {
      contacts: regionsState.regions
        .filter((region) => region.showOnContactView)
        .sort((regionA, regionB) => (regionA.name < regionB.name ? -1 : 1))
        .map((region) => ({
          name: region.name,
          phonePrimary: region.phonePrimary,
          phoneSecondary: region.phoneSecondary,
        })),
      title: t('Views.Contact.regionContactTitle'),
    },
  ]

  return (
    <Page
      data-test-id={ContactTestIds.pageWrapper}
      title={t('Views.Contact.title')}
    >
      <ContentStripe backgroundColor="secondary">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1" align="center">
              {t('Views.Contact.subtitle')}
            </Typography>
          </Grid>
        </Grid>
      </ContentStripe>

      <ContentStripe backgroundColor="fog">
        <Card>
          <CardContent className={clsx(classes.cardContent)}>
            <Box mb={4}>
              <Typography variant="h2" color="primary" gutterBottom>
                {t('Views.Contact.cardTitle')}
              </Typography>
              <Typography variant="h3" color="primary" paragraph>
                {t('Views.Contact.cardSubTitle')}
              </Typography>
            </Box>

            <Grid container spacing={6} alignItems="flex-start">
              <Grid item md={6} xs={12}>
                {contactSections.map((section) => (
                  <Grid container key={section.title}>
                    <Grid item xs={12}>
                      <Typography gutterBottom align="left">
                        <strong>{section.title}</strong>
                      </Typography>
                    </Grid>
                    {section.contacts.map((contact) => (
                      <Fragment key={contact.name}>
                        <Grid item container alignItems="center">
                          <Grid item xs={12} sm={6}>
                            <Typography
                              gutterBottom
                              align="left"
                              component="pre"
                              variant="h4"
                            >
                              {contact.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              align={xsDown ? 'left' : 'right'}
                              color="primary"
                              component="p"
                              variant="h3"
                            >
                              {contact.phonePrimary}
                            </Typography>
                            {contact.phoneSecondary && (
                              <Box marginTop={1}>
                                <Typography
                                  align={xsDown ? 'left' : 'right'}
                                  color="primary"
                                  component="p"
                                  variant="h3"
                                >
                                  {contact.phoneSecondary}
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider
                            style={{ margin: `${theme.spacing(1)}px 0` }}
                          />
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                ))}
              </Grid>
              <Grid md={6} xs={12} item>
                <ContactForm />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ContentStripe>

      <CertificateStripe />
    </Page>
  )
}

export default ContactView
