import type { FC } from 'react'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  generatePath,
  Link as RouterLink,
  useLocation,
  useParams,
} from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import {
  DoneAllOutlined,
  DraftsOutlined,
  FindInPageOutlined,
  MailOutline,
  MailOutlined,
} from '@material-ui/icons'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { Alert, AlertTitle } from '@material-ui/lab'
import { TFunction } from 'i18next'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import useUserDate from 'src/hooks/useUserDate'
import api from 'src/services/api'
import Button from 'src/components/Button/Button'
import CertificateStripe from 'src/components/CertificateStripe/CertificateStripe'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Page from 'src/components/Page/Page'

import useRegions from '../../hooks/useRegions'
import { Region, RegionStatus } from '../../types'

import { Step, StepType } from './Step'

import useStyles from './FormSuccessView.styles'

const FormSuccessIds = TestIds.views.formSuccess

const MEMBERSHIP_CONTEXT = 'membership'
const CONTRACT_CONTEXT = 'contracts'

const contract_next_steps = (t: TFunction): StepType[] => [
  {
    icon: MailOutlined,
    label: t('Views.FormSuccess.contractNextSteps.step1'),
    step: 1,
  },
  {
    icon: FindInPageOutlined,
    label: t('Views.FormSuccess.contractNextSteps.step2'),
    step: 2,
  },
  {
    icon: DoneAllOutlined,
    label: t('Views.FormSuccess.contractNextSteps.step3'),
    step: 3,
  },
  {
    icon: DraftsOutlined,
    label: t('Views.FormSuccess.contractNextSteps.step4'),
    step: 4,
  },
]

const membership_next_steps = (t: TFunction): StepType[] => [
  {
    icon: MailOutline,
    label: t('Views.FormSuccess.membershipNextSteps.step1'),
    step: 1,
  },
  {
    icon: FindInPageOutlined,
    label: t('Views.FormSuccess.membershipNextSteps.step2'),
    step: 2,
  },
  {
    icon: DoneAllOutlined,
    label: t('Views.FormSuccess.membershipNextSteps.step3'),
    step: 3,
  },
]

const FormSuccessView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { hasMembershipStatus } = useUserDate()
  const { getters: regionGetters } = useRegions()
  const [loading, setLoading] = useState<boolean>(false)
  const location = useLocation()
  const confirm = useConfirm()
  const { applicationId, regionSlug, employmentType } = useParams<{
    applicationId?: string
    regionSlug?: string
    employmentType?: string
  }>()

  const region = regionGetters.getRegion({
    by: 'slug',
    value: regionSlug || '',
  })

  const context = location.pathname.includes('vertraege')
    ? CONTRACT_CONTEXT
    : location.pathname.includes('mitglied')
    ? MEMBERSHIP_CONTEXT
    : undefined

  const handleClick = (region: Region) => {
    let redirectPath = ''

    if (context === CONTRACT_CONTEXT) {
      if (region.membershipStatus === RegionStatus.PASSIVE) {
        redirectPath = generatePath(routes.root.routes!.membership.path)
      } else {
        redirectPath = generatePath(
          routes.root.routes!.membership.routes!.membershipForm.path,
          {
            regionSlug: regionSlug,
          },
        )
      }
    } else if (context === MEMBERSHIP_CONTEXT) {
      if (region.contractStatus === RegionStatus.PASSIVE) {
        redirectPath = generatePath(routes.root.routes!.contracts.path)
      } else {
        redirectPath = generatePath(
          routes.root.routes!.contracts.routes!.contractList.path,
          {
            regionSlug: regionSlug,
          },
        )
      }
    }
    return redirectPath
  }

  const filenameSuffix =
    context === CONTRACT_CONTEXT ? 'HZV-Antrag' : 'Mitgliedsantrag'

  const downloadContext =
    context === CONTRACT_CONTEXT ? 'applications' : 'memberships'

  const stepsList =
    context === CONTRACT_CONTEXT
      ? contract_next_steps(t)
      : membership_next_steps(t)

  const whatNextLabel =
    context === CONTRACT_CONTEXT
      ? t('Views.FormSuccess.contractNextSteps.whatNext')
      : t('Views.FormSuccess.membershipNextSteps.whatNext')

  const isOfficeOwnerEmploymentType = employmentType === '1'

  return (
    <Page
      className={classes.root}
      data-test-id={FormSuccessIds.pageWrapper}
      title={t('Views.FormSuccess.title')}
    >
      <ContentStripe backgroundColor="secondary">
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" align="center" gutterBottom>
              {t('Views.FormSuccess.header')}
            </Typography>
          </Grid>
        </Grid>
      </ContentStripe>

      <ContentStripe backgroundColor="default">
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center" color={'primary'}>
              {whatNextLabel}
            </Typography>
          </Grid>
          {region?.contractApplicationRequiresEmployerAuthorisation &&
            !isOfficeOwnerEmploymentType && (
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  align="center"
                  color={'textPrimary'}
                  gutterBottom
                >
                  {t(
                    'Views.FormSuccess.contractNextSteps.employerAuthInformation.title',
                  )}
                </Typography>
                <Typography align="center" color={'textPrimary'}>
                  <Trans
                    i18nKey="Views.FormSuccess.contractNextSteps.employerAuthInformation.description"
                    components={{
                      bold: <b />,
                    }}
                  />
                </Typography>
              </Grid>
            )}
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="stretch"
            >
              {stepsList.map(({ label, icon, step }) => (
                <Grid item key={`${step}`}>
                  <Step step={step} icon={icon} label={label} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/* in case the user has already a membership we hide the button in contract success page */}
          {((!hasMembershipStatus && context === CONTRACT_CONTEXT) ||
            context === MEMBERSHIP_CONTEXT) && (
            <Grid item>
              <Button
                data-test-id={TestIds.views.errorView.backToStartButton}
                color="secondary"
                component={RouterLink}
                to={() => region && handleClick(region)}
                variant="contained"
              >
                {context === CONTRACT_CONTEXT
                  ? t('Views.FormSuccess.becomeAMember')
                  : t('Views.FormSuccess.getHzvContract')}
              </Button>
            </Grid>
          )}
          {applicationId && (
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                startIcon={
                  loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CloudDownloadIcon />
                  )
                }
                onClick={() => {
                  setLoading(true)
                  api
                    .downloadReportFile(downloadContext, applicationId)
                    .then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data]),
                      )
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute(
                        'download',
                        `${filenameSuffix}_${applicationId}_${regionSlug}.pdf`,
                      )
                      document.body.appendChild(link)
                      link.click()
                    })
                    .catch(() =>
                      confirm({
                        cancellationButtonProps: { style: { display: 'none' } },
                        content: (
                          <Alert severity="error">
                            <AlertTitle>
                              {t('General.somethingWentWrong')}
                            </AlertTitle>
                          </Alert>
                        ),
                        title: t('General.error'),
                      }),
                    )
                    .finally(() => setLoading(false))
                }}
              >
                {context === CONTRACT_CONTEXT
                  ? t('Views.FormSuccess.hzvContractDownload')
                  : t('Views.FormSuccess.membershipContractDownload')}
              </Button>
            </Grid>
          )}
        </Grid>
      </ContentStripe>

      <CertificateStripe />
      <ContactStripe />
    </Page>
  )
}

export default FormSuccessView
