import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Link, Typography } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import { SHOW_SSO_BANNER, ssoLoginUrl } from 'src/constants'
import Button from 'src/components/Button/Button'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'

import useStyles from './SsoFetch.styles'

export interface SsoFetchInterface {
  applicationType: 'contracts' | 'membership'
}

export const SsoFetch: React.FC<SsoFetchInterface> = ({ applicationType }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  if (!SHOW_SSO_BANNER) {
    return <></>
  }
  return (
    <ContentStripe backgroundColor="secondary" py={1} className={classes.root}>
      <Grid alignItems="center" container justifyContent="center" spacing={2}>
        <Grid item xs={12} lg={'auto'}>
          <HelpOutlineIcon fontSize={'large'} className={classes.blockItem} />
        </Grid>
        <Grid item xs={12} lg={'auto'}>
          <Typography align={'center'} variant={'body2'}>
            {t('Views.Home.sso.doYouHaveAccountHint')}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={'auto'}>
          <Typography
            align={'center'}
            variant={'body2'}
            className={classes.bold}
          >
            {t('Views.Home.sso.saveTimeHint')}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={'auto'}>
          <Button
            component={Link}
            href={ssoLoginUrl(applicationType)}
            variant="contained"
            color={'fog'}
            className={classes.blockItem}
            style={{ maxWidth: 300 }}
          >
            {t('Views.Home.sso.buttonLabel')}
          </Button>
        </Grid>
      </Grid>
    </ContentStripe>
  )
}
