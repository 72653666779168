import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterLink } from 'react-router-dom'
import { Box, Grid, Typography } from '@material-ui/core'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import useUserData from 'src/hooks/useUserDate'
import Button from 'src/components/Button/Button'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Page from 'src/components/Page/Page'

import useStyles from './HomeView.styles'

const HomeTestIds = TestIds.views.home

const HomeView: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { hasMembershipStatus } = useUserData()
  return (
    <Page
      className={classes.root}
      data-test-id={HomeTestIds.pageWrapper}
      title={t('Views.Home.title')}
    >
      <ContentStripe
        backgroundColor="home"
        backgroundImg="/static/images/hzv-background.jpeg"
        data-test-id={HomeTestIds.heroTeaser}
        isMainContent
        fullHeight
      >
        <Grid
          alignContent="center"
          alignItems="center"
          container
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} md={10}>
            <Box mb={4}>
              <Typography gutterBottom variant="h1" align="center">
                {t('Views.Home.header')}
              </Typography>

              <Typography
                align="center"
                component="h2"
                gutterBottom
                variant="h4"
              >
                {t('Views.Home.subheader')}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Button
              color="tertiary"
              component={RouterLink}
              exact
              fullWidth
              size="large"
              to={routes.root.routes!.contracts.path}
              variant="contained"
              data-test-id={HomeTestIds.contractsButton}
            >
              {t('Views.Home.buttonContracts')}
            </Button>
          </Grid>

          {!hasMembershipStatus && (
            <Grid item xs={12} sm={6} md={4}>
              <Button
                color="tertiary"
                component={RouterLink}
                exact
                fullWidth
                size="large"
                to={routes.root.routes!.membership.path}
                variant="contained"
                data-test-id={HomeTestIds.membershipButton}
              >
                {t('Views.Home.buttonMembership')}
              </Button>
            </Grid>
          )}
        </Grid>
      </ContentStripe>
    </Page>
  )
}

export default HomeView
