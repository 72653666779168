import type { FC } from 'react'
import React from 'react'
import type { BoxProps, ContainerProps } from '@material-ui/core'
import { Box, Container } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './ContentStripe.styles'

export interface ContentStripeProps
  extends Omit<BoxProps, 'children' | 'maxWidth'>,
    Pick<ContainerProps, 'children' | 'disableGutters' | 'fixed' | 'maxWidth'> {
  alt?: string
  backgroundColor?:
    | 'default'
    | 'secondary'
    | 'fog'
    | 'home'
    | 'contracts'
    | 'membership'
  backgroundImg?: string
  fullHeight?: boolean
  isMainContent?: boolean
}

export const ContentStripe: FC<ContentStripeProps> = ({
  alt = '',
  backgroundColor,
  backgroundImg,
  children,
  className,
  disableGutters,
  fixed,
  fullHeight,
  maxWidth = 'lg',
  isMainContent = false,
  ...props
}) => {
  const classes = useStyles()
  let backgroundClass: string = classes.backgroundDefault

  switch (backgroundColor) {
    case 'default':
      backgroundClass = classes.backgroundDefault

      break
    case 'secondary':
      backgroundClass = classes.backgroundSecondary

      break
    case 'fog':
      backgroundClass = classes.backgroundFog

      break

    case 'home':
      backgroundClass = classes.backgroundHome

      break
    case 'contracts':
      backgroundClass = classes.backgroundContracts

      break
    case 'membership':
      backgroundClass = classes.backgroundMembership

      break
    default:
      backgroundClass = classes.backgroundDefault
  }

  const combinedImgClass = isMainContent
    ? `${classes.image} ${classes.imageFilter}`
    : classes.image

  return (
    <Box
      className={clsx(
        classes.root,
        backgroundClass,
        fullHeight && classes.fullHeight,
        className,
      )}
      py={4}
      {...props}
    >
      {!!backgroundImg && (
        <img className={combinedImgClass} src={backgroundImg} alt={alt} />
      )}

      <Container
        className={clsx(classes.container)}
        disableGutters={disableGutters}
        fixed={fixed}
        maxWidth={maxWidth}
      >
        {children}
      </Container>
    </Box>
  )
}

export default ContentStripe
